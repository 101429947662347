<template>
  <div class="sbwcfg-page">
    <x-table
      new-style
      :no-data-text="CA('bkcfg_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
    >
    </x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="一体机" prop="aio">
          <Select v-model="form.aio" clearable style="width: 300px">
            <Option
              v-for="(item, index) in ytjList"
              :value="item.value + '/' + item.label"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="板卡名称" prop="deviceName">
          <Input v-model="form.deviceName" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="板卡厂商" prop="device">
          <Select v-model="form.device" style="width: 300px">
            <Option
              v-for="(item, index) in BKType"
              :key="index"
              :value="`${item.num}/${item.name}`"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="备注" prop="remark">
          <Input
            v-model="form.remark"
            type="textarea"
            :rows="3"
            style="width: 300px"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import { Switch } from "view-design";
import aisleSbwTb from "./aisleSbwTb";
export default {
  name: "",
  props: {
    farmOptions: Array,
    aioGuid: String,
    ytjList: Array,
  },
  components: {
    aisleSbwTb,
  },
  data() {
    return {
      table: {
        columns: [
          {
            type: "expand",
            width: 60,
            render: (h, { row }) => {
              return row.id ? (
                <aisleSbwTb props={{ sbwObj: row }}></aisleSbwTb>
              ) : (
                <p>--</p>
              );
            },
          },
          {
            title: "所属设备",
            minWidth: 100,
            key: "aioName",
          },
          {
            title: "板卡名称",
            minWidth: 100,
            key: "deviceName",
          },
          {
            title: "板卡厂商",
            minWidth: 100,
            key: "deviceKeyName",
          },
          {
            title: "备注",
            minWidth: 100,
            render: (h, { row }) => {
              return <span>{row.remark ? row.remark : "--"}</span>;
            },
          },
          {
            title: "板卡状态",
            width: 100,
            render: (h, { row }) => {
              return (
                <Switch
                  size="large"
                  true-value={1}
                  false-value={
                    +row.status === 0 ? 0 : +row.status === 2 ? 2 : ""
                  }
                  value={+row.status}
                  disabled={
                    +row.status == 1
                      ? this.CA("bkcfg_stop")
                        ? false
                        : true
                      : this.CA("bkcfg_open")
                      ? false
                      : true
                  }
                  loading={this.statusLoading}
                  onInput={(val) => this.statusChange(val, row.guid)}
                >
                  <span slot="open">启用</span>
                  <span slot="close">停用</span>
                </Switch>
              );
            },
          },
          {
            title: "操作",
            width: 100,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("bkcfg_update") && (
                    <a
                      class="iconfont"
                      title="编辑"
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      &#xe669;
                    </a>
                  )}
                  {this.CA("bkcfg_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.guid)}
                    >
                      <a class="iconfont" title="删除">
                        &#xe66a;
                      </a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: "添加板卡",
            ca: "bkcfg_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "上位一体机",
              component: "input",
              field: "aioName",
              defaultValue: "",
            },
          ],
        },

        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      statusLoading: false,
      form: {
        aio: "",
        device: "",
        deviceName: "",
        maxNodeAddress: 0,
        maxRangAddress: 0,
        minNodeAddress: 0,
        minRangAddress: 0,
        remark: "",
      },
      rules: {
        aio: [{ required: true, message: "请选择一体机" }],
        device: [{ required: true, message: "请选择板卡厂商" }],
        deviceName: [{ required: true, message: "请填写板卡名称" }],
      },

      BKType: [],
      search_data: {},
    };
  },
  methods: {
    search(data) {
      this.config.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },
    add() {
      this.modal = {
        show: true,
        title: "添加板卡",
        submitLoading: false,
      };
    },
    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    getList() {
      if (!this.CA("bkcfg_check")) return;
      this.table.loading = true;
      this.$post(this.$api.DEVICES.SBWLIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getBKType() {
      this.$post(this.$api.DICT.SYS_LIST, {
        name: "485设备类型",
      }).then((res) => {
        this.BKType = res;
      });
    },
    statusChange(status, guid) {
      this.$post(
        status == "1"
          ? this.$api.BUSSDEVICEXPH.OPEN
          : this.$api.BUSSDEVICEXPH.STOP,
        {
          status,
          guid,
        }
      )
        .then(() => {
          this.$Message.success(status == "1" ? "启用成功" : "停用成功");
          this.getList();
        })
        .finally(() => {
          this.statusLoading = false;
        });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.guid = row.guid;
      this.form.id = row.id;
      this.form.device = `${row.deviceKey}/${row.deviceKeyName}`;
      this.form.aio = `${row.aioGuid}/${row.aioName}`;
      this.modal = {
        show: true,
        title: "编辑板卡",
        submitLoading: false,
      };
    },
    del(guid) {
      this.$post(this.$api.BUSSDEVICEXPH.DELETE, {
        guid,
      }).then((res) => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        aio: "",
        device: "",
        deviceName: "",
        maxNodeAddress: 0,
        maxRangAddress: 0,
        minNodeAddress: 0,
        minRangAddress: 0,
        remark: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.deviceKey = this.form.device.split("/")[0];
        params.deviceKeyName = this.form.device.split("/")[1];
        params.aioGuid = this.form.aio.split("/")[0];
        params.aioName = this.form.aio.split("/")[1];
        delete params.device;
        delete params.aio;
        this.modal.submitLoading = true;
        this.$post(
          params.guid
            ? this.$api.BUSSDEVICEXPH.UPDATE
            : this.$api.BUSSDEVICEXPH.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
    this.getBKType();
  },
};
</script>

<style lang="less" scoped>
.sbwcfg-page {
  width: 100%;
  height: 100%;
  .table-header {
    display: flex;
    flex-wrap: nowrap;
    padding: 16px;
    .search-pane {
      display: flex;
      flex-wrap: wrap;
      flex-shrink: 1;
      flex-grow: 1;
      span {
        padding-right: 16px;
      }
    }
  }
  .footer {
    text-align: right;
    padding: 16px;
  }
}
/deep/ td.ivu-table-expanded-cell {
  background: #efefef;
}
</style>