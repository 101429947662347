<template>
  <div class="sbw-inner">
    <x-table
      new-style
      :no-data-text="CA('bktd_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :notHeight="true"
      size="small"
      :config="config"
      @add="add"
      ref="xtable"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :label-width="100">
        <FormItem
          label="通道名称"
          prop="nodeName"
          :rules="{ required: true, message: '请填写通道名称' }"
          v-if="form.guid"
        >
          <Input v-model="form.nodeName" style="width: 300px"></Input>
        </FormItem>
        <FormItem
          label="地址号"
          prop="beginAddressNo"
          v-if="!form.guid"
          :rules="
            ({ required: true, message: '请填写地址号' },
            {
              validator(rule, value, callback) {
                if (!util.validateNumber(value)) {
                  callback(rule.message);
                  return;
                }
                if (value > form.endAddressNo) {
                  callback(rule.message);
                  return;
                }
                callback();
              },
              message: '请填写正确的地址号',
            })
          "
        >
          <Input v-model="form.beginAddressNo" style="width: 144px"></Input>~
          <Input v-model="form.endAddressNo" style="width: 144px"></Input>
        </FormItem>
        <FormItem
          label="节点号"
          prop="beginNodeNo"
          v-if="!form.guid"
          :rules="
            ({ required: true, message: '请填写地址号' },
            {
              validator(rule, value, callback) {
                if (!util.validateNumber(value)) {
                  callback(rule.message);
                  return;
                }
                if (value > form.endNodeNo) {
                  callback(rule.message);
                  return;
                }
                callback();
              },
              message: '请填写正确的地址号',
            })
          "
        >
          <Input v-model="form.beginNodeNo" style="width: 144px"></Input>~
          <Input v-model="form.endNodeNo" style="width: 144px"></Input>
        </FormItem>
        <FormItem label="备注" prop="remark">
          <Input
            v-model="form.remark"
            type="textarea"
            :rows="3"
            style="width: 300px"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import CU from "@/common/util";
export default {
  name: "",
  props: {
    sbwObj: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      util: CU,
      table: {
        columns: [
          {
            title: "名称",
            key: "nodeName",
            minWidth: 100,
          },
          {
            title: "地址号",
            key: "nodeAddress",
            minWidth: 100,
          },
          {
            title: "节点号",
            key: "addressNo",
            minWidth: 100,
          },
          {
            title: "备注",
            key: "remark",
            minWidth: 100,
          },
          {
            title: "状态",
            key: "statusName",
            minWidth: 100,
          },
          {
            title: "操作",
            width: 100,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("bktd_edit") && (
                    <a
                      class="iconfont"
                      title="编辑"
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      &#xe669;
                    </a>
                  )}
                  {this.CA("bktd_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.guid)}
                    >
                      <a class="iconfont" title="删除">
                        &#xe66a;
                      </a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: "添加通道",
            ca: "bktd_add",
          },
        },
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },

      form: {
        beginAddressNo: 0,
        beginNodeNo: 0,
        endAddressNo: 0,
        endNodeNo: 0,
        remark: "",
        nodeName: "",
      },
    };
  },
  methods: {
    add() {
      this.modal = {
        show: true,
        title: "添加通道",
        submitLoading: false,
      };
    },
    getList() {
      if (!this.CA("bktd_check")) return;
      this.table.loading = true;
      this.$post(this.$api.AISLE.SBW_AISLELIST, {
        pageNo: 1,
        pageSize: 9999,
        deviceId: this.sbwObj.guid,
      })
        .then((res) => {
          this.table.data = res.list;
          this.$refs.xtable.setNextProps();
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.guid = row.guid;
      this.modal = {
        show: true,
        title: "编辑通道",
        submitLoading: false,
      };
    },
    del(guid) {
      this.$post(this.$api.AISLE.SBW_AISLEDEL, {
        guid,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        beginAddressNo: 0,
        beginNodeNo: 0,
        endAddressNo: 0,
        endNodeNo: 0,
        remark: "",
        nodeName: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        if (params.guid) {
          delete params.beginAddressNo;
          delete params.endAddressNo;
          delete params.beginNodeNo;
          delete params.endNodeNo;
        }
        this.modal.submitLoading = true;
        this.$post(
          params.guid
            ? this.$api.AISLE.SBW_AISLEEDIT
            : this.$api.AISLE.SBW_AISLENEW,
          {
            ...params,
            deviceId: this.sbwObj.guid,
            createUserId: localStorage.userId,
          }
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
  },
  watch: {
    sbwObj(n) {
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.sbw-inner {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
}
/deep/ .ivu-table-wrapper {
  height: 100%;
}

/deep/ .ivu-table.ivu-table-default {
  height: 100%;
}

/deep/ .ivu-table-header {
  top: 0;
  left: 0;
  height: 36.7px;
}

/deep/ .ivu-table-body {
  height: calc(100% - 36.7px);
  overflow-y: auto;
  z-index: 990;
}
</style>