var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sbw-inner"},[_c('x-table',{ref:"xtable",attrs:{"new-style":"","no-data-text":_vm.CA('bktd_check') ? '暂无数据' : '暂无数据查询权限',"columns":_vm.table.columns,"data":_vm.table.data,"loading":_vm.table.loading,"notHeight":true,"size":"small","config":_vm.config},on:{"add":_vm.add}}),_c('Modal',{attrs:{"title":_vm.modal.title},on:{"on-visible-change":_vm.modalShow},model:{value:(_vm.modal.show),callback:function ($$v) {_vm.$set(_vm.modal, "show", $$v)},expression:"modal.show"}},[_c('Form',{ref:"form",attrs:{"model":_vm.form,"label-width":100}},[(_vm.form.guid)?_c('FormItem',{attrs:{"label":"通道名称","prop":"nodeName","rules":{ required: true, message: '请填写通道名称' }}},[_c('Input',{staticStyle:{"width":"300px"},model:{value:(_vm.form.nodeName),callback:function ($$v) {_vm.$set(_vm.form, "nodeName", $$v)},expression:"form.nodeName"}})],1):_vm._e(),(!_vm.form.guid)?_c('FormItem',{attrs:{"label":"地址号","prop":"beginAddressNo","rules":({ required: true, message: '请填写地址号' },
          {
            validator(rule, value, callback) {
              if (!_vm.util.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              if (value > _vm.form.endAddressNo) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: '请填写正确的地址号',
          })}},[_c('Input',{staticStyle:{"width":"144px"},model:{value:(_vm.form.beginAddressNo),callback:function ($$v) {_vm.$set(_vm.form, "beginAddressNo", $$v)},expression:"form.beginAddressNo"}}),_vm._v("~ "),_c('Input',{staticStyle:{"width":"144px"},model:{value:(_vm.form.endAddressNo),callback:function ($$v) {_vm.$set(_vm.form, "endAddressNo", $$v)},expression:"form.endAddressNo"}})],1):_vm._e(),(!_vm.form.guid)?_c('FormItem',{attrs:{"label":"节点号","prop":"beginNodeNo","rules":({ required: true, message: '请填写地址号' },
          {
            validator(rule, value, callback) {
              if (!_vm.util.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              if (value > _vm.form.endNodeNo) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: '请填写正确的地址号',
          })}},[_c('Input',{staticStyle:{"width":"144px"},model:{value:(_vm.form.beginNodeNo),callback:function ($$v) {_vm.$set(_vm.form, "beginNodeNo", $$v)},expression:"form.beginNodeNo"}}),_vm._v("~ "),_c('Input',{staticStyle:{"width":"144px"},model:{value:(_vm.form.endNodeNo),callback:function ($$v) {_vm.$set(_vm.form, "endNodeNo", $$v)},expression:"form.endNodeNo"}})],1):_vm._e(),_c('FormItem',{attrs:{"label":"备注","prop":"remark"}},[_c('Input',{staticStyle:{"width":"300px"},attrs:{"type":"textarea","rows":3},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)],1),_c('p',{staticStyle:{"text-align":"center"},attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{attrs:{"type":"primary","loading":_vm.modal.submitLoading},on:{"click":_vm.submit}},[_vm._v("提交")]),_c('Button',{on:{"click":() => (_vm.modal.show = false)}},[_vm._v("取消")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }